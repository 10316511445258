
.dark-mode {
  background-color: #222;
  color: white;
  /* Add other dark mode styles here */
}
.dashbg{
  /* background-color:#f1f2f3; */
  color: black;
}
/* .table-container {
  max-height: 400px; 
  overflow-y: auto;
} */
input.largerCheckbox {
  width: 15px;
  height: 31px;
}

/* my style */



@tailwind base;
@tailwind components;
@tailwind utilities;
