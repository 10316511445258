/* Modal.css */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    border-radius: 10px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.modal form {
    display: flex;
    flex-direction: column;
}

.modal form div {
    margin-bottom: 15px;
}

.modal form button {
    padding: 10px 20px;
    background-color: #3085d6;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal form button:hover {
    background-color: #2874a6;
}
.selectox {
    background-color: rgb(255, 249, 249);
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 4px;
    padding: 8px;
}