.card {
  padding: 20px;
  margin: 20px auto;
  max-width: 984px;
  background: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0px 24px 60px -1px rgba(37,44,54,0.14);
}

.container {
  max-width: 700px;
  margin: 0 auto;
}

.card-title {
  margin-bottom: 30px;
  text-align: center;
}

.card-title h2 {
  margin: 0;
}

.card-body {
  margin-bottom: 30px;
}

.payment-type {
  margin-bottom: 20px;
}

.types {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.type {
  width: calc(33.33% - 10px);
  margin-bottom: 10px;
  background: #f2f4f7;
  border: 2px solid #e8ebed;
  padding: 15px;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.type img {
  max-width: 80px;
  height: auto;
  margin-bottom: 10px;
}

.type p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.card-actions {
  display: flex;
  justify-content: center;
}

.button {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.5px;
  padding: 15px 25px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.5s ease;
  background: transparent;
  border: 2px solid transparent;
}

.button.button-primary {
  background: #40b3ff;
  color: #fff;
}

.button.button-primary:hover {
  background: #218fd9;
}

.active {
  border-color: green;
}

@media screen and (max-width: 600px) {
  .type {
      width: 100%;
  }
}

input {
  border: none; /* Remove the border */
  outline: none; /* Remove the outline when focused */
  
}